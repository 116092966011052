<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card elevation="10" :loading="loading" class="ma-4" width="100%">
        <v-card-title>
          Index Stocks
          <v-spacer />
          <v-select
            :items="Object.keys(indices)"
            :loading="selectLoading"
            :disabled="loading"
            v-model="selectedIndex"
            @change="indexChanged"
            single-line
            hide-details
            label="Select Index"
            outlined
            id="index-selector"
          />
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="stocks"
          :search="search"
          :sort-by="['symbol']"
          :sort-desc="[false]"
          no-data-text="Loading stocks..."
        >
          <template v-slot:[`item.l52`]="{ item }">
            <span class="error--text">{{ item.l52 }}</span>
          </template>
          <template v-slot:[`item.h52`]="{ item }">
            <span class="success--text">{{ item.h52 }}</span>
          </template>
          <template v-slot:[`item.dh`]="{ item }">
            <span class="success--text">{{ item.dh }}</span>
          </template>
          <template v-slot:[`item.dl`]="{ item }">
            <span class="error--text">{{ item.dl }}</span>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <span
              :class="
                item.updated === 'up'
                  ? 'success--text'
                  : item.updated === 'down'
                  ? 'error--text'
                  : ''
              "
            >
              {{ item.price }}
            </span>
          </template>
          <template v-slot:[`item.chgp`]="{ item }">
            <span :class="item.chgp > 0.0 ? 'success--text' : 'error--text'">
              {{ item.chgp }}
            </span>
          </template>
          <template v-slot:[`item.hlp`]="{ item }">
            <span :class="item.hlp > 1.0 ? 'success--text' : 'error--text'">
              {{ item.hlp }}
            </span>
          </template>
        </v-data-table>
      </v-card>
      <v-col class="text-center text-h4" lg="3" md="4" sm="6" cols="9">
        <StatusSnackbar
          :show="showSnackbar"
          :text="snackbarText"
          :type="snackbarType"
          @close="showSnackbar = false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
input#index-selector {
  position: absolute;
  display: inline;
  width: 0;
  height: 0;
  padding: 0;
}
</style>

<script>
import axios from 'axios'
import StatusSnackbar from '@/components/StatusSnackbar'
import YFinance from 'yfinance-live'

export default {
  name: 'Home',
  components: {
    StatusSnackbar,
  },
  data: () => ({
    loading: true,
    selectLoading: true,
    search: '',
    headers: [
      { text: 'Company', value: 'company', align: 'start', width: '300px' },
      { text: 'Symbol', value: 'symbol', align: 'start' },
      {
        text: '52W Low',
        value: 'l52',
        align: 'end',
        class: 'error--text',
      },
      {
        text: '52W High',
        value: 'h52',
        align: 'end',
        class: 'success--text',
      },
      { text: 'Open', value: 'open', align: 'end' },
      { text: 'Day High', value: 'dh', align: 'end', class: 'success--text' },
      { text: 'Day Low', value: 'dl', align: 'end', class: 'error--text' },
      { text: 'Curr.', value: 'price', align: 'end' },
      { text: 'Chg.%', value: 'chgp', align: 'end' },
      { text: 'H%/L%', value: 'hlp', align: 'end' },
    ],
    selectedIndex: null,
    indices: {},
    stocks: [],
    showSnackbar: false,
    snackbarType: 'success',
    snackbarText: '',
    yfinancelive: null,
  }),
  methods: {
    indexChanged() {
      this.loading = true
      this.yfinancelive.stop()
      this.fetchStockData()
    },
    async fetchIndices() {
      return axios.get('/api/indices').then(res => {
        this.indices = res.data.indices
        this.selectedIndex = Object.keys(this.indices)[0]
        this.selectLoading = false
      })
    },
    fetchStockData() {
      let axiosReqs = []
      let chunkSize = 101
      for (
        let i = 0, j = this.indices[this.selectedIndex].constituents.length;
        i < j;
        i += chunkSize
      ) {
        axiosReqs.push(
          axios.get('/api/stocksdata', {
            params: {
              symbols: JSON.stringify(
                this.indices[this.selectedIndex].constituents.slice(
                  i,
                  i + chunkSize
                )
              ),
              indian: this.indices[this.selectedIndex].indian,
            },
          })
        )
      }
      axios
        .all(axiosReqs)
        .then(
          axios.spread((...responses) => {
            this.stocks = [].concat.apply(
              [],
              responses.map(res => res.data.data)
            )
          })
        )
        .catch(err => {
          this.snackbarText = `Error fetching stocks: ${err.message}`
          this.snackbarType = 'error'
          this.showSnackbar = true
        })
        .then(() => {
          this.loading = false
          this.snackbarText = `Success fetching stocks!`
          this.snackbarType = 'success'
          this.showSnackbar = true
          this.yfinancelive = YFinance(
            this.stocks.map(stock => stock.symbol),
            this.handleLiveStockData
          )
        })
    },
    clearPriceUpdated(stockIdx) {
      this.stocks[stockIdx].updated = null
    },
    handleLiveStockData(data) {
      let stockIdx = this.stocks.findIndex(stock => stock.symbol === data.id)
      clearTimeout(this.stocks[stockIdx].timeout)
      if (data.price.toFixed(2) != this.stocks[stockIdx].price) {
        this.stocks[stockIdx].updated =
          data.price.toFixed(2) > this.stocks[stockIdx].price ? 'up' : 'down'
        this.stocks[stockIdx].timeout = setTimeout(
          () => this.clearPriceUpdated(stockIdx),
          1000
        )
      }
      this.stocks[stockIdx].price = data.price.toFixed(2)
      if (this.stocks[stockIdx].price > this.stocks[stockIdx].high) {
        this.stocks[stockIdx].high = data.price.toFixed(2)
      }
      if (this.stocks[stockIdx].price < this.stocks[stockIdx].low) {
        this.stocks[stockIdx].high = data.price.toFixed(2)
      }
      this.stocks[stockIdx].chgp = (
        (100 * (data.price.toFixed(2) - this.stocks[stockIdx].open)) /
        this.stocks[stockIdx].open
      ).toFixed(3)
    },
  },
  mounted() {
    this.fetchIndices().then(() => {
      this.fetchStockData()
    })
  },
  beforeRouteLeave(_to, _from, next) {
    if (this.yfinancelive) this.yfinancelive.stop()
    next()
  },
}
</script>
